import React from "react";
import {
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayInput,
    SelectArrayInput,
    Button,
    useRecordContext,
    useRefresh,
} from "react-admin";
import CLUB_FIELDS from "../../utils/constants/labels/ClubFields";
import dataProviderIndex from "../../providers";
import {LIVESTREAM} from "../../utils/constants/CUSTOM_ACTIONS";
import RESOURCES from "../../utils/constants/resources";

const ClubForm = () => {
    const record = useRecordContext();

    return (
        <div>
            {/*<ImageInput*/}
            {/*  source={CLUB_FIELDS.logo.source}*/}
            {/*  label={CLUB_FIELDS.logo.label}*/}
            {/*  fullWidth*/}
            {/*>*/}
            {/*  <ImageField source="src" title={CLUB_FIELDS.logo.title} />*/}
            {/*</ImageInput>*/}
            <TextInput
                source={CLUB_FIELDS.name.source}
                label={CLUB_FIELDS.name.label}
                fullWidth
            />
            <TextInput
                source={CLUB_FIELDS.city.source}
                label={CLUB_FIELDS.city.label}
                fullWidth
            />

            <ReferenceInput
                source={CLUB_FIELDS.country.referenceSource}
                reference={CLUB_FIELDS.country.reference}
            >
                <AutocompleteInput source={CLUB_FIELDS.country.source} label={CLUB_FIELDS.country.label} fullWidth/>
            </ReferenceInput>

            <ReferenceArrayInput
                source={CLUB_FIELDS.scopes.referenceSource}
                reference={CLUB_FIELDS.scopes.reference}
            >
                <SelectArrayInput label={CLUB_FIELDS.scopes.label} fullWidth/>
            </ReferenceArrayInput>

            <Button
                label="Convert to Livestream"
                onClick={() => dataProviderIndex(LIVESTREAM, RESOURCES.clubs, {id: record?.id})}
                disabled={record.scopes.includes('Livestream')}
                variant="contained"
                color="primary"
            />

        </div>
    )
};
export default ClubForm;
