import {fetchUtils} from "react-admin";
import { API_BASE_URL} from "../constants/url";

const refreshAccessToken = async () => {
  const response = await fetch(`${API_BASE_URL}/auth/refresh-token`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Client-Type": "web",
    },
    credentials: "include",
  });


  if (!response.ok) {
    throw new Error("Session expired");
  }

  const { accessToken } = await response.json();
  localStorage.setItem("token", accessToken);
};

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  options.headers.set("X-Client-Type", "web");

  try {
    return await fetchUtils.fetchJson(url, options);
  } catch (error) {
    if (error.status === 401) {
        try {
          await refreshAccessToken();
         
        } catch (refreshError) {
          localStorage.removeItem("token");
          window.location.href = "/login"; // Redirect to login page
          throw refreshError;
        }
      return httpClient(url, options); // Retry the original request with the new token
    }

    throw error;
  }
};

export default httpClient;
